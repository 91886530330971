<template>
    <defaultSec :title="'事件组'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="组名称">
                    <el-input v-model="formParam.eventGroupName" placeholder="组名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="组备注">
                    <el-input v-model="formParam.eventGroupRemark" placeholder="组备注" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="initList">查询</el-button>
                    <el-button type="success" size="mini" @click="add">新建</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标识" prop="eventGroupId" width="80"></el-table-column>
                <el-table-column label="组名称" prop="eventGroupName" width="190"></el-table-column>
                <el-table-column label="排序" prop="sort" width="80"></el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="200">
                </el-table-column>
                <el-table-column label="备注说明" prop="eventGroupRemark" ></el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning " size="mini"
                                       @click="toDetail(scope.row)">修改
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>

        <el-dialog title="事件组" :visible.sync="showDialog">
            <el-form :model="model" label-width="120px">
                <el-form-item label="组id" prop="eventGroupId" v-show="false">
                    <el-input v-model="model.eventGroupId" placeholder="请输入组名称"></el-input>
                </el-form-item>
                <el-form-item label="组名称" prop="eventGroupName" :required="true">
                    <el-input v-model="model.eventGroupName" placeholder="请输入组名称"></el-input>
                    <span style="color: red">*</span>
                </el-form-item>
                <el-form-item label="备注" prop="eventGroupRemark">
                    <el-input v-model="model.eventGroupRemark" placeholder="请输入组备注"></el-input>
                </el-form-item>
                <el-form-item label="排序" :required="true">
                    <el-input-number v-model="model.sort" :min="1" :max="1000" placeholder=""></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-button @click="showDialog = false" size="mini">取 消</el-button>
                    <el-button type="primary" size="mini" @click="save()" class="header-btn">
                        保存
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    eventGroupName: '',
                    eventGroupRemark: ''
                },
                dataList: [],
                showDialog: false,
                model: {
                    eventGroupId: '',
                    eventGroupName: '',
                    eventGroupRemark: '',
                    eventGroupImg: '',
                    sort: 0,
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                this.dataList=[]
                this.$apiGet('lechun-cms/event/getEventGroupList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            add(){
                this.showDialog = true;
                this.model= {
                    eventGroupId: '',
                        eventGroupName: '',
                        eventGroupRemark: '',
                        eventGroupImg: '',
                        sort: 1,
                }
            },
            toDetail(row){
                this.showDialog = true;
                this.model = {
                    eventGroupId: row.eventGroupId,
                    eventGroupName: row.eventGroupName,
                    eventGroupRemark: row.eventGroupRemark,
                    eventGroupImg: row.eventGroupImg,
                    sort: row.sort
                }
            },
            save(){
                if(this.model.eventGroupName==''){
                    this.$message({
                        showClose: true,
                        message: '事件分组必填',
                        type: 'error'
                    });
                    return;
                }
                this.$apiGet('lechun-cms/event/saveEventGroup', this.model).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.showDialog=false
                    this.initList()
                })
            },
            formatRefundType: function (row, column) {
                if (row.refundType == 1) {
                    return "退款";
                }
                if (row.refundType == 2) {
                    return "退货退款";
                }
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>